/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Grab single block with Ctrl key",
  "description": "Adds the ability to drag a single block out of the middle of a script (instead of the entire stack attached below it) while holding the Ctrl key.",
  "info": [
    {
      "text": "On macOS, use the Cmd key instead of the Ctrl key.",
      "id": "macContextDisabled"
    },
    {
      "text": "If \"flip controls\" is enabled, grabbing blocks individually will be the default behavior. Hold Ctrl to drag the entire stack.",
      "id": "flipControls"
    }
  ],
  "credits": [
    {
      "name": "lisa_wolfgang",
      "link": "https://scratch.mit.edu/users/lisa_wolfgang/"
    },
    {
      "name": "GarboMuffin"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "name": "Flip controls",
      "id": "invertDrag",
      "type": "boolean",
      "default": false
    }
  ],
  "tags": [],
  "dynamicDisable": true,
  "enabledByDefault": true
};
export default manifest;
