/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Block palette category icons",
  "description": "Adds icons inside the colored circles that identify block categories.",
  "tags": [
    "theme"
  ],
  "enabledByDefault": false,
  "dynamicDisable": true,
  "userstyles": [
    {
      "url": "userstyle.css"
    }
  ],
  "credits": [
    {
      "name": "--Explosion--",
      "link": "https://scratch.mit.edu/users/--Explosion--/"
    },
    {
      "name": "RedGuy7",
      "link": "https://scratch.mit.edu/users/RedGuy7/"
    }
  ]
};
export default manifest;
