/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Duplicate script with Alt key",
  "description": "Quickly duplicate a script by dragging it while holding the Alt key. Hold Ctrl as well to only duplicate a single block instead of the entire stack attached below it.",
  "credits": [
    {
      "name": "GarboMuffin"
    }
  ],
  "info": [
    {
      "text": "On macOS, use the Option key instead of the Alt key and Command key instead of the Control key.",
      "id": "mac"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "dynamicDisable": true,
  "tags": [],
  "enabledByDefault": true
};
export default manifest;
