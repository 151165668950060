/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "2D color picker",
  "description": "Replaces saturation and brightness sliders in the costume editor with a 2D color picker. Hold Shift while dragging the cursor to change the values on a single axis.",
  "tags": [],
  "credits": [
    {
      "name": "Ucrash"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "userstyles": [
    {
      "url": "style.css"
    }
  ],
  "enabledByDefault": false
};
export default manifest;
