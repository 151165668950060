/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Variable manager",
  "description": "Adds a tab next to \"sounds\" in the editor for easily updating variables and lists.",
  "credits": [
    {
      "name": "Jeffalo"
    },
    {
      "name": "GarboMuffin"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "userstyles": [
    {
      "url": "style.css"
    }
  ],
  "dynamicDisable": true,
  "tags": [],
  "enabledByDefault": false
};
export default manifest;
