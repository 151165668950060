/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Developer tools",
  "description": "Adds a find box and new menu options to the editor: copy/paste blocks, better clean up, go to custom block definition, and more! Press Ctrl+F to focus the find box, Ctrl+Space to bring up the \"add block by name\" popup, Ctrl+Left/Right to navigate find history.",
  "credits": [
    {
      "name": "griffpatch"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "name": "Enhance \"Clean up Blocks\"",
      "id": "enableCleanUpPlus",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Paste blocks at mouse cursor",
      "id": "enablePasteBlocksAtMouse",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Middle mouse click variables, custom blocks or events to navigate",
      "id": "enableMiddleClickFinder",
      "type": "boolean",
      "default": true
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "userstyles": [
    {
      "url": "userscript.css"
    }
  ],
  "tags": [
    "recommended"
  ],
  "enabledByDefault": true
};
export default manifest;
