/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Editor comment previews",
  "description": "Allows you to preview the contents of comments by hovering over collapsed comments and blocks. You can use this to view comments that are off-screen, identify a loop block from the bottom by its preview, fit many long comments in a small space, and more.",
  "tags": [],
  "credits": [
    {
      "name": "lisa_wolfgang",
      "link": "https://scratch.mit.edu/users/lisa_wolfgang/"
    },
    {
      "name": "GarboMuffin"
    }
  ],
  "userstyles": [
    {
      "url": "userstyle.css"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "name": "Hover collapsed comments to preview",
      "id": "hover-view",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Hover blocks to preview attached comments",
      "id": "hover-view-block",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Hover custom blocks to preview definition comments",
      "id": "hover-view-procedure",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Delay duration",
      "id": "delay",
      "type": "select",
      "potentialValues": [
        {
          "name": "None",
          "id": "none"
        },
        {
          "name": "Short",
          "id": "short"
        },
        {
          "name": "Long",
          "id": "long"
        }
      ],
      "default": "short"
    },
    {
      "dynamic": true,
      "name": "Follow mouse",
      "id": "follow-mouse",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Reduce transparency",
      "id": "reduce-transparency",
      "type": "boolean",
      "default": false
    },
    {
      "dynamic": true,
      "name": "Reduce animation",
      "id": "reduce-animation",
      "type": "boolean",
      "default": false
    }
  ],
  "dynamicDisable": true,
  "enabledByDefault": true
};
export default manifest;
