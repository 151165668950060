/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Sprite deletion confirmation",
  "description": "Asks if you're sure when deleting a sprite inside a project.",
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "dynamicDisable": true,
  "tags": [
    "recommended"
  ],
  "enabledByDefault": false
};
export default manifest;
