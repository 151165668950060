/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Colored context menus",
  "description": "Makes block right click context menus colorful.",
  "credits": [
    {
      "name": "GarboMuffin"
    }
  ],
  "dynamicDisable": true,
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "userstyles": [
    {
      "url": "userscript.css"
    }
  ],
  "tags": [
    "theme"
  ],
  "enabledByDefault": false
};
export default manifest;
