/* generated by pull.js */
import _cat_blocks from "../addons/cat-blocks/_manifest_entry.js";
import _editor_devtools from "../addons/editor-devtools/_manifest_entry.js";
import _editor_searchable_dropdowns from "../addons/editor-searchable-dropdowns/_manifest_entry.js";
import _data_category_tweaks_v2 from "../addons/data-category-tweaks-v2/_manifest_entry.js";
import _block_palette_icons from "../addons/block-palette-icons/_manifest_entry.js";
import _hide_flyout from "../addons/hide-flyout/_manifest_entry.js";
import _mediarecorder from "../addons/mediarecorder/_manifest_entry.js";
import _drag_drop from "../addons/drag-drop/_manifest_entry.js";
import _debugger from "../addons/debugger/_manifest_entry.js";
import _pause from "../addons/pause/_manifest_entry.js";
import _mute_project from "../addons/mute-project/_manifest_entry.js";
import _clones from "../addons/clones/_manifest_entry.js";
import _mouse_pos from "../addons/mouse-pos/_manifest_entry.js";
import _color_picker from "../addons/color-picker/_manifest_entry.js";
import _remove_sprite_confirm from "../addons/remove-sprite-confirm/_manifest_entry.js";
import _block_count from "../addons/block-count/_manifest_entry.js";
import _onion_skinning from "../addons/onion-skinning/_manifest_entry.js";
import _default_costume_editor_color from "../addons/default-costume-editor-color/_manifest_entry.js";
import _bitmap_copy from "../addons/bitmap-copy/_manifest_entry.js";
import _2d_color_picker from "../addons/2d-color-picker/_manifest_entry.js";
import _better_img_uploads from "../addons/better-img-uploads/_manifest_entry.js";
import _custom_block_shape from "../addons/custom-block-shape/_manifest_entry.js";
import _editor_theme3 from "../addons/editor-theme3/_manifest_entry.js";
import _custom_block_text from "../addons/custom-block-text/_manifest_entry.js";
import _editor_colored_context_menus from "../addons/editor-colored-context-menus/_manifest_entry.js";
import _editor_stage_left from "../addons/editor-stage-left/_manifest_entry.js";
import _editor_buttons_reverse_order from "../addons/editor-buttons-reverse-order/_manifest_entry.js";
import _variable_manager from "../addons/variable-manager/_manifest_entry.js";
import _search_sprites from "../addons/search-sprites/_manifest_entry.js";
import _gamepad from "../addons/gamepad/_manifest_entry.js";
import _editor_sounds from "../addons/editor-sounds/_manifest_entry.js";
import _folders from "../addons/folders/_manifest_entry.js";
import _block_switching from "../addons/block-switching/_manifest_entry.js";
import _load_extensions from "../addons/load-extensions/_manifest_entry.js";
import _custom_zoom from "../addons/custom-zoom/_manifest_entry.js";
import _initialise_sprite_position from "../addons/initialise-sprite-position/_manifest_entry.js";
import _blocks2image from "../addons/blocks2image/_manifest_entry.js";
import _remove_curved_stage_border from "../addons/remove-curved-stage-border/_manifest_entry.js";
import _transparent_orphans from "../addons/transparent-orphans/_manifest_entry.js";
import _paint_by_default from "../addons/paint-by-default/_manifest_entry.js";
import _block_cherry_picking from "../addons/block-cherry-picking/_manifest_entry.js";
import _hide_new_variables from "../addons/hide-new-variables/_manifest_entry.js";
import _editor_extra_keys from "../addons/editor-extra-keys/_manifest_entry.js";
import _hide_delete_button from "../addons/hide-delete-button/_manifest_entry.js";
import _no_script_bumping from "../addons/no-script-bumping/_manifest_entry.js";
import _disable_stage_drag_select from "../addons/disable-stage-drag-select/_manifest_entry.js";
import _move_to_top_bottom from "../addons/move-to-top-bottom/_manifest_entry.js";
import _disable_paste_offset from "../addons/disable-paste-offset/_manifest_entry.js";
import _block_duplicate from "../addons/block-duplicate/_manifest_entry.js";
import _swap_local_global from "../addons/swap-local-global/_manifest_entry.js";
import _editor_comment_previews from "../addons/editor-comment-previews/_manifest_entry.js";
import _columns from "../addons/columns/_manifest_entry.js";
import _script_snap from "../addons/script-snap/_manifest_entry.js";
import _fullscreen from "../addons/fullscreen/_manifest_entry.js";
import _hide_stage from "../addons/hide-stage/_manifest_entry.js";
import _tw_straighten_comments from "../addons/tw-straighten-comments/_manifest_entry.js";
import _tw_remove_backpack from "../addons/tw-remove-backpack/_manifest_entry.js";
import _tw_remove_feedback from "../addons/tw-remove-feedback/_manifest_entry.js";
import _tw_disable_compiler from "../addons/tw-disable-compiler/_manifest_entry.js";
import _editor_stepping from "../addons/editor-stepping/_manifest_entry.js";
export default {
  "cat-blocks": _cat_blocks,
  "editor-devtools": _editor_devtools,
  "editor-searchable-dropdowns": _editor_searchable_dropdowns,
  "data-category-tweaks-v2": _data_category_tweaks_v2,
  "block-palette-icons": _block_palette_icons,
  "hide-flyout": _hide_flyout,
  "mediarecorder": _mediarecorder,
  "drag-drop": _drag_drop,
  "debugger": _debugger,
  "pause": _pause,
  "mute-project": _mute_project,
  "clones": _clones,
  "mouse-pos": _mouse_pos,
  "color-picker": _color_picker,
  "remove-sprite-confirm": _remove_sprite_confirm,
  "block-count": _block_count,
  "onion-skinning": _onion_skinning,
  "default-costume-editor-color": _default_costume_editor_color,
  "bitmap-copy": _bitmap_copy,
  "2d-color-picker": _2d_color_picker,
  "better-img-uploads": _better_img_uploads,
  "custom-block-shape": _custom_block_shape,
  "editor-theme3": _editor_theme3,
  "custom-block-text": _custom_block_text,
  "editor-colored-context-menus": _editor_colored_context_menus,
  "editor-stage-left": _editor_stage_left,
  "editor-buttons-reverse-order": _editor_buttons_reverse_order,
  "variable-manager": _variable_manager,
  "search-sprites": _search_sprites,
  "gamepad": _gamepad,
  "editor-sounds": _editor_sounds,
  "folders": _folders,
  "block-switching": _block_switching,
  "load-extensions": _load_extensions,
  "custom-zoom": _custom_zoom,
  "initialise-sprite-position": _initialise_sprite_position,
  "blocks2image": _blocks2image,
  "remove-curved-stage-border": _remove_curved_stage_border,
  "transparent-orphans": _transparent_orphans,
  "paint-by-default": _paint_by_default,
  "block-cherry-picking": _block_cherry_picking,
  "hide-new-variables": _hide_new_variables,
  "editor-extra-keys": _editor_extra_keys,
  "hide-delete-button": _hide_delete_button,
  "no-script-bumping": _no_script_bumping,
  "disable-stage-drag-select": _disable_stage_drag_select,
  "move-to-top-bottom": _move_to_top_bottom,
  "disable-paste-offset": _disable_paste_offset,
  "block-duplicate": _block_duplicate,
  "swap-local-global": _swap_local_global,
  "editor-comment-previews": _editor_comment_previews,
  "columns": _columns,
  "script-snap": _script_snap,
  "fullscreen": _fullscreen,
  "hide-stage": _hide_stage,
  "tw-straighten-comments": _tw_straighten_comments,
  "tw-remove-backpack": _tw_remove_backpack,
  "tw-remove-feedback": _tw_remove_feedback,
  "tw-disable-compiler": _tw_disable_compiler,
  "editor-stepping": _editor_stepping,
};
