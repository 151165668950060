/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Do not automatically space overlapping scripts",
  "description": "Allows scripts to be moved and modified without causing overlapping scripts to move around.",
  "credits": [
    {
      "name": "GarboMuffin"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "dynamicDisable": true,
  "tags": [],
  "enabledByDefault": false
};
export default manifest;
