/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Snap scripts to grid",
  "description": "Drag a script to automatically align its position to the code area dots.",
  "tags": [],
  "credits": [
    {
      "name": "CST1229",
      "link": "https://scratch.mit.edu/users/CST1229/"
    },
    {
      "name": "TheColaber",
      "link": "https://scratch.mit.edu/users/TheColaber/"
    },
    {
      "name": "lisa_wolfgang",
      "link": "https://scratch.mit.edu/users/lisa_wolfgang/"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "name": "Grid size (px)",
      "id": "grid",
      "type": "integer",
      "min": 1,
      "default": 40
    }
  ],
  "presets": [
    {
      "name": "Whole-block",
      "id": "whole-block",
      "values": {
        "grid": 48
      }
    },
    {
      "name": "Half-block",
      "id": "half-block",
      "values": {
        "grid": 24
      }
    },
    {
      "name": "Default",
      "id": "default",
      "values": {
        "grid": 40
      }
    }
  ],
  "dynamicDisable": true,
  "enabledByDefault": false
};
export default manifest;
