/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Block transparency",
  "description": "Adjust the transparency for blocks in the editor, with separate options for orphaned blocks (those without a hat block at the top) and blocks that are being dragged.",
  "tags": [],
  "dynamicDisable": true,
  "credits": [
    {
      "name": "ErrorGamer2000",
      "link": "https://scratch.mit.edu/users/ErrorGamer2000/"
    },
    {
      "name": "GarboMuffin"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "name": "Block transparency (%)",
      "id": "block",
      "type": "integer",
      "min": 0,
      "max": 100,
      "default": 0
    },
    {
      "dynamic": true,
      "name": "Orphaned transparency (%)",
      "id": "orphan",
      "type": "integer",
      "min": 0,
      "max": 100,
      "default": 25
    },
    {
      "dynamic": true,
      "name": "Dragged transparency (%)",
      "id": "dragged",
      "type": "integer",
      "min": 0,
      "max": 100,
      "default": 25
    }
  ],
  "userstyles": [
    {
      "url": "userstyle.css"
    }
  ]
};
export default manifest;
